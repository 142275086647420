import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import starryMeBgMd from "../../Assets/Projects/starry-me-bg-md.webp";
import starryMeMd from "../../Assets/Projects/starry-me-md.webp";
import swapyMd from "../../Assets/Projects/swapy-md.webp";
import swapyNameLogoMd from "../../Assets/Projects/swapy-name-logo-md.webp";

import ProjectCard from "./ProjectCard";

function Projects() {
  return (
    <Container fluid className="project-section" style={{ overflow: "hidden" }}>
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ marginBottom: 0 }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {/* Starry Me */}
          <Col md={12}>
            <ProjectCard
              backgroundImg={starryMeBgMd}
              left={
                <Col lg={7} className="starry-me-col-1">
                  <h3 className="fw-bold" style={{ fontSize: 64 }}>
                    Starry Me
                  </h3>
                  <p style={{ fontSize: 20 }}>
                    Starry Me is your personal emotional exploration. Discover,
                    understand, and navigate your emotions in a personalized and
                    engaging way.
                  </p>
                  <div className="mt-4">
                    <ViewProjectButton to="/project/starry-me" />
                  </div>
                </Col>
              }
              right={
                <Col lg={5} md={8} className="offset-lg-0 offset-md-4">
                  <img
                    src={starryMeMd}
                    alt="Starry Me"
                    style={{
                      width: "100%",
                      transform: "scale(1.25)",
                    }}
                  />
                </Col>
              }
            />
          </Col>
          {/* Swapy */}
          <Col md={12}>
            <ProjectCard
              backgroundColor="#9E1D22"
              left={
                <Col lg={4} md={5}>
                  <img
                    src={swapyMd}
                    alt="Swapy"
                    style={{ width: "100%", transform: "scale(1.1)" }}
                  />
                </Col>
              }
              right={
                <Col lg={8} md={7} className="swapy-col-2">
                  <img
                    src={swapyNameLogoMd}
                    alt="Swapy"
                    style={{ width: "100%" }}
                  />
                  <div className="d-flex justify-content-end mt-5">
                    <ViewProjectButton to="/project/swapy" />
                  </div>
                </Col>
              }
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

function ViewProjectButton({ to }) {
  return (
    <Button className="btn-lg rounded-0 bg-white text-black" as={Link} to={to}>
      View Project
    </Button>
  );
}
