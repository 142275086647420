import { Row } from "react-bootstrap";

function ProjectCard(props) {
  const { backgroundImg, backgroundColor, left, right } = props;

  return (
    <Row
      className="align-items-center"
      style={{
        color: "white",
        backgroundColor: backgroundColor,
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: 50,
      }}
    >
      {left}
      {right}
    </Row>
  );
}

export default ProjectCard;
