import ProjectDetail from "./ProjectDetail";

import StarryMe1 from "../../Assets/Projects/StarryMe1_compressed.pdf";
import StarryMe2 from "../../Assets/Projects/StarryMe2.pdf";

import { starryMeVideo } from "../../constants";

function StarryMeDetail() {
  return (
    <ProjectDetail
      pdf1={StarryMe1}
      pdf2={StarryMe2}
      videoId={starryMeVideo}
      color="#4D5AB5"
    />
  );
}

export default StarryMeDetail;
