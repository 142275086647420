import ProjectDetail from "./ProjectDetail";

import Swapy1 from "../../Assets/Projects/Swapy1_compressed.pdf";
import Swapy2 from "../../Assets/Projects/Swapy2.pdf";

import { swapyVideo } from "../../constants";

function SwapyDetail() {
  return (
    <ProjectDetail
      pdf1={Swapy1}
      pdf2={Swapy2}
      videoId={swapyVideo}
      color="#9E1D22"
    />
  );
}

export default SwapyDetail;
