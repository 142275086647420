import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import aboutBanner from "../../Assets/about-banner.webp";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row>
          <Col md={12}>
            <img
              src={aboutBanner}
              style={{ width: "100%" }}
              alt="about-banner"
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={7} style={{ justifyContent: "center", paddingTop: "20px" }}>
            <p>Hello Everyone.</p>
            <p>
              I'm <b className="purple">Thanamas Pongmala</b>, a UX/UI Designer
              based in Bangkok, Thailand.
            </p>
            <p>
              With a background in landscape architecture, I've transitioned
              into the UX/UI field due to my passion for creating intuitive
              digital experiences.
            </p>
            <p>
              I've developed two mobile applications as a freelance UX/UI
              Designer, currently in the process of being published.
            </p>
            <p>
              <Link to="/project">
                <b className="purple">You can check it out here!</b>
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
