import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

function ProjectDetail(props) {
  const { pdf1, pdf2, videoId, color } = props;

  const [width, setWidth] = useState(1200);
  const [numPages, setNumPages] = useState(null);
  const [pagesRendered, setPagesRendered] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPagesRendered(0);
  }, []);

  const onRenderSuccess = useCallback(() => {
    setPagesRendered((prevState) => prevState + 1);
  }, []);

  const pagesRenderedPlusOne = useMemo(
    () => Math.min(pagesRendered + 1, numPages),
    [numPages, pagesRendered]
  );

  const paddingX = useMemo(() => {
    if (width > 991) return 80;
    if (width > 786) return 40;
    return 16;
  }, [width]);

  const fontSize = useMemo(
    () => 40 * ((width - paddingX * 2) / (1200 - paddingX * 2)),
    [width, paddingX]
  );

  return (
    <Container
      fluid
      style={{ padding: `80px ${paddingX}px`, overflow: "hidden" }}
    >
      <Row>
        {/* PDF 1 */}
        <Document file={pdf1} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
            const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
            const isLastPage = numPages === index + 1;
            const needsCallbackToRenderNextPage =
              isCurrentlyRendering && !isLastPage;

            return (
              <Page
                key={`page_${index + 1}`}
                onRenderSuccess={
                  needsCallbackToRenderNextPage ? onRenderSuccess : null
                }
                pageNumber={index + 1}
                width={width - paddingX * 2}
              />
            );
          })}
        </Document>
        {/* Video */}
        <Col style={{ padding: `0 ${paddingX}px` }}>
          <p
            className="fw-bold"
            style={{
              color: color,
              fontSize: fontSize,
            }}
          >
            Prototype
          </p>
          <LiteYouTubeEmbed id={videoId} />
        </Col>
        {/* PDF 2 */}
        <Document file={pdf2}>
          <Page pageNumber={1} width={width - paddingX * 2} />
        </Document>
      </Row>
    </Container>
  );
}

export default ProjectDetail;
