import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

import myImg from "../../Assets/portrait.webp";
import { linkedIn_URL, email_id } from "../../constants";

function Home() {
  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>Hello there! I'm Thanamas.</h1>
              <p className="home-about-body">
                I'm a UX/UI Designer based in Bangkok, Thailand.
                <br />
                <br />
                Currently working as a Freelancer, I have two mobile
                applications in development that are nearing publication.
                <br />
                <br />
                Take a look more{" "}
                <Link to="/resume">
                  <b className="purple">resume</b>
                </Link>{" "}
                or{" "}
                <Link to="/project">
                  <b className="purple">UX/UI projects</b>
                </Link>
              </p>
            </Col>
            <Col md={4} className="myAvtar">
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>FIND ME ON</h1>
              <p>
                Feel free to <span className="purple">connect </span>with me
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href={`mailto:${email_id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiOutlineMail />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href={linkedIn_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
